let EnterpriseNo = window.localStorage.getItem('EnterpriseNo') || ''

let isgetAllDeviceList = false  //智能入库设备列表,是否获取全部列表(目前兰博获取全部,其他不是)
let isShowCamera = true  //是否显示摄像头

if (EnterpriseNo === 'bamibuytest') {
    isgetAllDeviceList = true
}

if (EnterpriseNo === '202') {
    isgetAllDeviceList = true
    isShowCamera = false
}

export default {
    isgetAllDeviceList,
    isShowCamera,
}