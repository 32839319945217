<template>
  <!-- 智能入库 -->
  <div class="container">
    <div class="mainBOx">
      <div class="main_left">
        <div class="main_item_top">
          <div class="selectShebei">
            <div class="leftLabel">*选择设备:</div>
            <div class="rightInput">
              <el-select v-model="deviceId" filterable clearable>
                <el-option
                  v-for="(item, index) in devDatas"
                  :key="index"
                  :label="item.deviceName"
                  :value="item.deviceid"
                >
                  {{ item.deviceName }} ({{
                    item.isOnline == 1 ? "在线" : "离线"
                  }})
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="tipsTitle">扫描参数</div>
          <el-checkbox class="margin10px" v-model="isSave"
            >是否预报包裹自动入库</el-checkbox
          >
        </div>
        <div class="left_bottom">
          <div class="left_item_param">
            <div class="tipstit">是否拍照保存</div>
            <el-radio-group v-model="isPhotos">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
          <div class="left_item_param">
            <div class="tipstit">重量是否反转</div>
            <el-radio-group v-model="isReverse">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
          <div class="left_item_param">
            <div class="tipstit">图像信息</div>

            <!-- <img
              class="imgsA"
              v-if="ImgSrc"
              ref="myImgs"
              :src="ImgSrc"
              alt=""
            /> -->
            <el-image
              class="imgsA"
              v-if="ImgSrc"
              ref="myImgs"
              :src="ImgSrc"
              :preview-src-list="srcList"
            >
            </el-image>
            <!-- 摄像头 -->
            <div v-if="isShowCamera">
              <div style="color: red" v-if="!canimg">
                摄像头开启失败，请检查摄像头是否可用！
              </div>
              <!--开启摄像头-->
              <el-button
                type="primary"
                @click="callCamera"
                style="margin-right: 10px"
                v-if="!showCallCamera"
                >开启摄像头</el-button
              >
              <!--关闭摄像头-->
              <el-button
                v-if="showCallCamera"
                type="primary"
                @click="closeCamera"
                >关闭摄像头</el-button
              >
              <!--canvas截取流-->
              <video
                class="imgs"
                ref="video"
                width="278"
                height="250"
                autoplay
              ></video>

              <canvas
                class="canvas_s"
                ref="canvas"
                width="278"
                height="250"
              ></canvas>

              <!--图片展示-->

              <!--确认-->
              <!-- <el-button type="primary" @click="photograph">拍照</el-button> -->
            </div>
          </div>
          <audio src="">111</audio>
        </div>
      </div>
      <!-- 右边 -->
      <div class="main_right">
        <div class="main_item_top" ref="saoMiao">
          <div class="right_tipstit">扫描区</div>
          <el-form
            class="formClass"
            @submit.native.prevent
            :model="formData1"
            label-width="100px"
          >
            <div class="queryItem1">
              <el-form-item label="快递单号:">
                <el-input
                  ref="courierNumberInput"
                  v-model="formData1.courierNumber"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </div>

            <div class="queryItem1">
              <el-form-item label="会员号:">
                <el-select
                  @change="huiayu"
                  v-model="formData1.memberId"
                  placeholder="请选择"
                  clearable
                  filterable
                  remote
                  reserve-keyword
                  :remote-method="remoteMethod"
                  value-key="memberId"
                >
                  <el-option
                    v-for="(item, index) in memberList"
                    :label="item.memberId"
                    :value="item.memberId"
                    :key="index"
                  >
                    {{ item.memberId }} : ({{ item.nickname }})
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="会员名称:">
                <el-input
                  placeholder="请输入"
                  v-model="formData1.nickname"
                  readonly
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="重量kg:">
                <el-input
                  v-model="formData1.packageWeight"
                  placeholder="请输入"
                  clearable
                  @keyup.enter.native="keyupEnter"
                ></el-input>
              </el-form-item>
            </div>

            <div class="queryItem1">
              <el-form-item label="长cm:">
                <el-input
                  v-model="formData1.length"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="宽cm:">
                <el-input
                  v-model="formData1.wide"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="高cm:">
                <el-input
                  v-model="formData1.height"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!-- 操作区 -->
        <div class="right_center_box" ref="caoZuo">
          <div class="right_tipstit">操作区</div>

          <div style="width: 100%; margin: 0 0 20px 30px">
            <el-table
              border
              :data="GoodsItems"
              size="small"
              style="width: 90%"
              :header-cell-style="{ background: '#f0f0f0' }"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="60px"
              ></el-table-column>

              <el-table-column prop="itemName" min-width="150px" label="品名">
                <template slot-scope="scope">
                  <el-select
                    v-model="GoodsItems[scope.$index].itemNameCode"
                    filterable
                    clearable
                    class="inputstyle"
                    @change="changeItem($event, scope.$index)"
                  >
                    <el-option
                      v-for="(item, index) in qudaoList"
                      :key="index"
                      :label="item.showName"
                      :value="item.itemNameCode"
                    ></el-option>
                  </el-select>
                  <el-input
                    v-if="!GoodsItems[scope.$index].itemNameCode"
                    v-model="GoodsItems[scope.$index].zdyName"
                    placeholder="自定义品名"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="quantity"
                min-width="100px"
                align="center"
                label="数量"
              >
                <template slot-scope="scope">
                  <el-input
                    type="number"
                    placeholder="请输入"
                    class="inputstyle"
                    v-model.number="GoodsItems[scope.$index].quantity"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="unitPrice"
                min-width="100px"
                align="center"
                label="单价"
              >
                <template slot-scope="scope">
                  <el-input
                    type="number"
                    placeholder="请输入"
                    class="inputstyle"
                    v-model.number="GoodsItems[scope.$index].unitPrice"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="length"
                min-width="80px"
                align="center"
                label="长/cm"
              >
                <template slot-scope="scope">
                  <el-input
                    type="number"
                    placeholder="请输入"
                    class="inputstyle"
                    v-model.number="GoodsItems[scope.$index].length"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="width"
                min-width="80px"
                align="center"
                label="宽/cm"
              >
                <template slot-scope="scope">
                  <el-input
                    type="number"
                    placeholder="请输入"
                    class="inputstyle"
                    v-model.number="GoodsItems[scope.$index].width"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                prop="height"
                min-width="80px"
                align="center"
                label="高/cm"
              >
                <template slot-scope="scope">
                  <el-input
                    type="number"
                    placeholder="请输入"
                    class="inputstyle"
                    v-model.number="GoodsItems[scope.$index].height"
                  ></el-input>
                </template>
              </el-table-column>

              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.$index === 0" style="margin-right: 10px">
                    <el-button @click="addbtn()" size="mini">新增</el-button>
                  </div>
                  <div v-if="GoodsItems.length > 1">
                    <el-button @click="delbut(scope.$index)" size="mini"
                      >删除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <el-form
            class="formClass"
            @submit.native.prevent
            :model="formData1"
            label-width="100px"
          >
            <div class="queryItem1">
              <el-form-item label="快递单号:">
                <el-input v-model="formData1.courierNumber" disabled></el-input>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="所在仓库:">
                <el-select v-model="formData1.storageId" filterable>
                  <el-option
                    v-for="(item, index) in storageArr"
                    :label="item.storageName"
                    :value="item.id"
                    :key="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <!-- <div class="queryItem1">
              <el-form-item label="货物重量:">
                <el-input
                  placeholder="请输入"
                  v-model="formData1.packageWeight"
                ></el-input>
              </el-form-item>
            </div> -->
            <div class="queryItem1">
              <el-form-item label="件数:">
                <el-input
                  placeholder="请输入"
                  v-model="formData1.boxCount"
                ></el-input>
              </el-form-item>
            </div>

            <div class="queryItem1">
              <el-form-item label="货区:">
                <el-select
                  v-model="formData1.storageCargoareaNumber"
                  placeholder="请选择"
                  filterable
                  clearable
                  @change="selectHJ"
                >
                  <el-option
                    v-for="(item, index) in goodsArea"
                    :label="item.cargoareaName"
                    :value="item.cargoareaNumber"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="queryItem1">
              <el-form-item label="货架号:">
                <el-select
                  v-model="formData1.storageCargoareaFrameNumber"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in goodsBoxNum"
                    :label="item.frameName"
                    :value="item.frameNumber"
                    :key="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="textareaitem">
              <el-form-item label="包裹备注:">
                <el-input
                  placeholder="请输入"
                  type="textarea"
                  rows="2"
                  v-model="formData1.packageComment"
                ></el-input>
              </el-form-item>
            </div>

            <div class="textareaitem">
              <el-form-item label="入库备注:">
                <el-input
                  placeholder="请输入"
                  type="textarea"
                  rows="2"
                  v-model="formData1.rukuComment"
                ></el-input>
              </el-form-item>
            </div>

            <el-button
              @click="ruKuPackageBtn"
              style="background-color: #f0a261; color: #fff; border: none"
              class="ordinarybut1"
              >入库确认</el-button
            >
          </el-form>
        </div>
        <!-- 已扫描区 -->
        <div>
          <div class="right_tipstit">已扫描区</div>
          <el-table
            border
            ref="mytable"
            :data="tabelData"
            size="small"
            :height="tableHeigth"
            :header-cell-style="{ background: '#f0f0f0' }"
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              width="60px"
            ></el-table-column>

            <el-table-column
              prop="courierNumber"
              min-width="80px"
              label="快递单号"
            ></el-table-column>
            <el-table-column
              prop="packageWeight"
              min-width="80px"
              label="重量"
            ></el-table-column>
            <el-table-column
              prop="boxCount"
              min-width="80px"
              label="数量"
            ></el-table-column>
            <el-table-column
              prop="ruKuStatus"
              min-width="80px"
              label="入库状态"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="target"
              min-width="80px"
              label="异常信息"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import paramKey from "@/assets/js/paramKey";
import paging from "@/components/pagings.vue";
// import mp3Src from "@/assets/mp3/y1009.mp3";
// import mp3Src from "@/assets/mp3/8855.mp3";
import mp3Src from "@/assets/mp3/y682.mp3";
export default {
  components: {
    paging,
  },
  data() {
    return {
      isShowCamera: paramKey.isShowCamera,
      deviceId: "", //设备id
      expressNumArr: [{ a: "设备1" }], //设备列表
      canimg: false, //是否可以调起相机
      showCallCamera: false, //摄像机开启
      ImgSrc: "", //拍照图片
      srcList: [],
      formData1: {
        courierNumber: "",
        packageWeight: "",
        wide: "",
        height: "",
        length: "",
        boxCount: "1",
        memberId: "",
        storageCargoareaNumber: "",
        storageCargoareaFrameNumber: "",
        storageId: "",
        nickname: "",
        packageComment: "",
        rukuComment: "",
        packageItem: [],
      }, //扫描区
      tableHeigth: "300px",
      tabelData: [],
      memberList: [],
      storageArr: [],
      goodsArea: [], //货区
      goodsBoxNum: [], //货架
      isSave: true, //是否预报包裹自动入库
      isPhotos: 1, //是否拍照
      isReverse: 1, //是否重量反转
      devDatas: [], //设备列表
      fileNameList: [], //
      timers: "", //计时器

      isDT: false,
      diamemberList: [],
      ut_memberList: [],
      sizeList: [10, 20, 50, 100, 200],
      pageStart_u: 1, //  分页配置
      pageTotal_u: 50,
      pageCount_u: 0,

      audio: "",

      GoodsItems: [
        {
          itemName: "", //物品名称
          itemNameCode: "", //渠道物品名称代码
          quantity: "", //数量
          unitPrice: "", //单价
          zdyName: "", //
          height: 0,
          length: 0,
          width: 0,
          volume: 0,
          weight: 0,
        },
      ],
      qudaoList: [],
      isgetAllDeviceList: false,
    };
  },
  async created() {
    this.getVipList(); //获取会员列表
    this.getDeviceList(); //获取设备列表
    this.userCustomizedConfInfo();
    this.tabelData =
      JSON.parse(window.sessionStorage.getItem("tableDatas")) || [];
    await this.myGetStorageList(); //获取仓库
  },
  mounted() {
    let that = this;
    if (paramKey.isShowCamera) {
      this.callCamera();
    }

    this.$nextTick(() => {
      that.fetTableHeight();

      this.$refs.courierNumberInput.focus();
    });

    this.getItemNameList();
  },
  beforeDestroy() {
    clearInterval(this.timers);
    this.timers = null;
    console.log("timers", this.timers);
    this.closeCamera(); //关闭相机
  },
  destroyed() {
    this.clearTimer(); // 另外，也可以在组件完全销毁后再次清理，以防在beforeDestroy中未立即执行
    this.timers = null;
  },
  watch: {
    isSave(newVal, oldVal) {
      this.addUserCustomizedConf();
    },
    isPhotos(newVal, oldVal) {
      this.addUserCustomizedConf();
    },
    isReverse(newVal, oldVal) {
      this.addUserCustomizedConf();
    },
    //设备id变化
    deviceId(newVal, oldVal) {
      let that = this;
      if (!newVal) {
        clearInterval(that.timers);
        this.timers = null;
        return;
      }
    },

    //快递单号变化
    "formData1.courierNumber"(newVal, oldVal) {
      // if (newVal.length < 6) {
      //   return false;
      // }
      if (this.isPhotos == 1 && this.canimg) {
        this.photograph();
      }

      this.intellectRuKuDetail();
    },
  },
  methods: {
    //获取渠道物品名称
    getItemNameList() {
      Api.getItemNameList().then((res) => {
        this.qudaoList = res.data.result.data || [];
      });
    },
    changeItem(e, idx) {
      let code = e;
      if (!e) {
        this.GoodsItems[idx].itemName = "";
      }
      this.qudaoList.forEach((item) => {
        if (item.itemNameCode == code) {
          this.GoodsItems[idx].itemName = item.showName;
        }
      });
    },
    addbtn() {
      this.GoodsItems.push({
        itemName: "",
        itemNameCode: "",
        quantity: 0,
        unitPrice: "",
        zdyName: "",
        height: 0,
        length: 0,
        width: 0,
        volume: 0,
        weight: 0,
      });
    },

    delbut(index) {
      //  删除类型
      this.GoodsItems.splice(index, 1);
    },

    playAudio() {
      let that = this;
      if (this.audio) {
        return false;
      }
      this.audio = new Audio();
      this.audio.src = mp3Src;
      this.audio.loop = "loop";
      this.audio.play();

      //停止播放
      setTimeout(() => {
        that.audio.pause();
        this.audio = "";
      }, 1000);
    },

    keyupEnter() {
      // console.log("hahhahah");
      this.ruKuPackageBtn();
    },
    // 根据快递单号获取详情
    intellectRuKuDetail() {
      let that = this;
      return Api.intellectRuKuDetail({
        courierNumber: this.formData1.courierNumber,
      }).then((res) => {
        let datas = res.data.result || "";
        if (datas) {
          this.formData1.wide = datas.width || "";
          this.formData1.height = datas.height || "";
          this.formData1.length = datas.length || "";
          this.formData1.boxCount = datas.boxCount || "1";
          this.formData1.memberId = datas.memberId || "";
          this.formData1.storageCargoareaNumber = datas.cargoareaNumber || "";
          this.formData1.storageCargoareaFrameNumber = datas.frameNumber || "";
          // this.formData1.storageId = datas.aaa || '';
          this.formData1.nickname = datas.nickName || "";
          this.formData1.packageComment = datas.packageComment || "";
          this.formData1.rukuComment = datas.rukuComment || "";
          // this.formData1.packageItem = datas.packageItem || [];
          this.GoodsItems = datas.packageItem || [];
          this.GoodsItems.forEach((item) => {
            if (item.itemNameCode) {
              item.zdyName = "";
            } else {
              item.zdyName = item.itemName;
            }
          });

          // 如果有预报的自动入库
          if (this.isSave && this.formData1.memberId) {
            if (this.ImgSrc && this.fileNameList.length == 0) {
              // 有图片,但是还没上传成功,稍等一会
              setTimeout(() => {
                that.ruKuPackageBtn();
              }, 1300);
            } else {
              that.ruKuPackageBtn();
            }
          }
        }
      });
    },
    // 获取称重机设备重量数据
    async getWeight() {
      if (paramKey.isgetAllDeviceList) {
        let param = {
          deviceNo: this.deviceId,
        };
        await Api.getPic(param).then((res) => {
          let bas64 = res.data.result?.pic || "";
          if (bas64) {
            let ZNImgs = window.sessionStorage.getItem("ZNImgs") || "";
            if (ZNImgs === bas64) {
              if (!this.ImgSrc) {
                this.ImgSrc = "data:image/jpeg;base64," + bas64;
                this.srcList = [this.ImgSrc];
                this.uploadImgs(this.ImgSrc);
              } else if (this.ImgSrc && this.fileNameList.length == 0) {
                this.ImgSrc = "data:image/jpeg;base64," + bas64;
                this.srcList = [this.ImgSrc];
                this.uploadImgs(this.ImgSrc);
              }

              return false;
            }
            window.sessionStorage.setItem("ZNImgs", bas64);

            this.ImgSrc = "data:image/jpeg;base64," + bas64;
            this.srcList = [this.ImgSrc];
            this.uploadImgs(this.ImgSrc);
          }
        });

        await Api.getWeightJihe(param).then((res) => {
          let results = res.data.result || "";
          if (results) {
            this.formData1.packageWeight = results.weight || 0;
            this.formData1.length = results.length || 0;
            this.formData1.wide = results.width || 0;
            this.formData1.height = results.height || 0;

            if (results.barcode) {
              let ZNcourierNumber =
                window.sessionStorage.getItem("ZNcourierNumber") || "";

              if (ZNcourierNumber === results.barcode) {
                if (!this.formData1.courierNumber) {
                  this.formData1.courierNumber = results.barcode || "";
                }
                return false;
              }

              this.formData1.courierNumber = results.barcode || "";
              window.sessionStorage.setItem(
                "ZNcourierNumber",
                this.formData1.courierNumber
              );
            }
          }

          // ImgSrc
        });
      } else {
        let param = {
          deviceid: this.deviceId,
          isReverse: this.isReverse,
        };
        Api.getWeight(param).then((res) => {
          this.formData1.packageWeight = res.data.result.weight || 0;
          let ids = [];
          if (res.data.result.fileId) {
            ids = [res.data.result.fileId];
          }
          this.fileNameList = ids || [];
          // ImgSrc
        });
      }
    },
    // 用户定制参数配置(新增)
    addUserCustomizedConf() {
      let objs = {
        isPhotos: this.isPhotos || 0,
        isSave: this.isSave || false,
        isReverse: this.isReverse || 0,
      };
      let param = {
        configJson: JSON.stringify(objs), //客户的自定义配置json数据
        configType: "IntelligentWarehous", //配置类型(定好就不要改了)
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.addUserCustomizedConf(param).then((res) => {
        if (res.data.status == "success") {
          // console.log(res)
        }
      });
    },
    // 用户定制参数信息(回显)
    userCustomizedConfInfo() {
      Api.userCustomizedConfInfo().then((res) => {
        if (res.data.status == "success") {
          let datas = res.data.result || "";
          // console.log(datas);
          let configType = datas.configType;
          if (datas.configJson) {
            let configJson = JSON.parse(datas.configJson);
            this.isPhotos = configJson.isPhotos || 0;
            this.isSave = configJson.isSave || false;
            this.isReverse = configJson.isReverse || 0;
          }
        }
      });
    },
    // 获取设备列表
    getDeviceList() {
      let that = this;
      if (paramKey.isgetAllDeviceList) {
        Api.getAllDeviceList().then((res) => {
          let devDatas = res.data.result || [];
          this.devDatas = devDatas;
          if (devDatas.length > 0) {
            this.deviceId = devDatas[0].deviceid;
            that.timers = setInterval(() => {
              that.getWeight();
            }, 2000);
          }
        });
      } else {
        Api.getDeviceList().then((res) => {
          let devDatas = res.data.result || [];
          this.devDatas = devDatas;
          if (devDatas.length > 0) {
            this.deviceId = devDatas[0].deviceid;
            that.timers = setInterval(() => {
              that.getWeight();
            }, 2000);
          }
        });
      }
    },
    //远程搜索
    remoteMethod(e) {
      if (e.length > 2) {
        this.getVipList(e);
      }
    },
    // 确认按钮
    ruKuPackageBtn() {
      if (!this.formData1.memberId) {
        this.$confirm("当前包裹没有会员号,是否继续入库?", "提示:", {
          confirmButtonText: "继续入库",
          cancelButtonText: "取消",
        })
          .then((res) => {
            this.ruKuPackage();
          })
          .catch(() => {});
      } else {
        this.ruKuPackage();
      }
    },
    // 入库接口
    ruKuPackage() {
      let tableDatas = this.tabelData || [];
      if (!this.formData1.packageWeight || this.formData1.packageWeight == 0) {
        this.$message.warning("重量不能为0");
        return false;
      }
      this.formData1.packageItem = this.GoodsItems;

      let newForm = JSON.parse(JSON.stringify(this.GoodsItems)) || [];
      for (var a = 0; a < newForm.length; a++) {
        if (!newForm[a].itemName && newForm[a].zdyName) {
          newForm[a].itemName = newForm[a].zdyName;
          newForm[a].itemNameCode = "";
        }
      }
      let arrItes = newForm || [];
      for (var i = 0; i < arrItes.length; i++) {
        delete arrItes[i].zdyName;
        if (arrItes[i].itemName == "") {
          arrItes.splice(i, 1);
          i--;
        }
      }

      let parm = {
        courierNumber: this.formData1.courierNumber, //快递单号
        packageWeight: this.formData1.packageWeight, //包裹重量(kg)
        wide: this.formData1.wide, //宽
        length: this.formData1.length, //长
        height: this.formData1.height, //高
        expressName: "", //快递公司名称
        fileNames: this.fileNameList || [], //照片数组
        isError: 0, //是否加入异常库1=是 0=否
        items: arrItes, //物品信息
        boxCount: this.formData1.boxCount, //件数
        memberId: this.formData1.memberId, //会员号
        storageCargoareaNumber: this.formData1.storageCargoareaNumber, //货区编号
        storageCargoareaFrameNumber: this.formData1.storageCargoareaFrameNumber, //货架编号
        storageId: this.formData1.storageId, //仓库id
        packageComment: this.formData1.packageComment, //包裹备注
        rukuComment: this.formData1.rukuComment, //入库备注
      };

      parm.sign = tools.getSign(parm);

      let tablesRows = {
        courierNumber: this.formData1.courierNumber, //快递单号
        packageWeight: this.formData1.packageWeight, //包裹重量(kg)
        boxCount: this.formData1.boxCount, //件数
      };
      Api.ruKuPackage(parm)
        .then((res) => {
          if (res.data.status === "success") {
            this.$message.success(res.data.message);
          }
          this.playAudio();
          tablesRows.ruKuStatus = "成功";
          tablesRows.target = res.data.target;
          tableDatas.push(tablesRows);
          window.sessionStorage.setItem(
            "tableDatas",
            JSON.stringify(tableDatas)
          );
          // this.formData1 = {
          this.formData1.courierNumber = "";
          this.formData1.packageWeight = "";
          this.formData1.wide = "";
          this.formData1.height = "";
          this.formData1.length = "";
          this.formData1.boxCount = "1";
          this.formData1.memberId = "";
          // this.formData1.storageCargoareaNumber = "";
          this.formData1.storageCargoareaFrameNumber = "";
          // this.formData1.storageId = "";
          this.formData1.nickname = "";
          this.formData1.packageComment = "";
          this.formData1.rukuComment = "";
          // this.formData1.packageItem = [];

          this.srcList = [];
          this.ImgSrc = "";
          // };
        })
        .catch((err) => {
          console.log(err);
          tablesRows.ruKuStatus = "失败";
          tablesRows.target = err.data.message + " " + err.data.target;
          tableDatas.push(tablesRows);
          window.sessionStorage.setItem(
            "tableDatas",
            JSON.stringify(tableDatas)
          );
        });
    },
    // 获取会员列表
    getVipList(e) {
      //  获取会员列表

      Api.getMemberDownList({ memberId: e || "" }).then((res) => {
        if (res.data.status === "success") {
          this.memberList = res.data.result.data || [];
        }
      });
    },
    // 选择会员号
    huiayu(e) {
      this.memberList.forEach((item) => {
        if (e == item.memberId) {
          this.formData1.nickname = item.nickname;
          this.getStorageCargoareaIdArr();
        }
      });
    },
    //  获取仓库数组数据
    myGetStorageList() {
      // Api.loginUserStorageDownList().then((res) => {
      //   if (res.data.status === "success") {
      //     this.storageArr = res.data.result || [];
      //     if (this.storageArr.length > 0) {
      //       this.formData1.storageId = this.storageArr[0].storageId;
      //       this.getStorageCargoareaIdArr();
      //     }
      //   }
      // });
      return Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.storageArr = res.data.result || [];
          if (this.storageArr.length > 0 && !this.formData1.storageId) {
            this.formData1.storageId = this.storageArr[0].id;
            this.getStorageCargoareaIdArr();
          }
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表API`);
        }
      });
    },
    //  获取货区
    getStorageCargoareaIdArr() {
      Api.getStorageCargoareaChooseList({
        storageId: this.formData1.storageId,
        memberId: this.formData1.memberId || "",
      }).then((res) => {
        if (res.data.status === "success") {
          this.goodsArea = res.data.result;
          if (this.goodsArea.length > 0) {
            this.formData1.storageCargoareaNumber =
              this.goodsArea[0].cargoareaNumber;
          }
          if (
            this.formData1.storageCargoareaNumber !== null ||
            this.formData1.storageCargoareaNumber !== ""
          ) {
            this.getFrameNum(this.formData1.storageCargoareaNumber);
          }
        } else {
          console.log(`${res.data.message}--获取货区`);
        }
      });
    },
    selectHJ(val) {
      //  选择获区时候变化
      if (val) {
        this.getFrameNum(val);
      } else {
        this.formData1.storageCargoareaFrameNumber = "";
      }
    },
    //  获取货架id
    getFrameNum(val) {
      let index =
        this.goodsArea.findIndex((v) => v.cargoareaNumber === val) || 0;
      let ids = "";
      if (this.goodsArea.length > 0 && this.goodsArea[index]) {
        ids = this.goodsArea[index].id;
      }
      Api.getStorageCargoareaFrameChooseList({
        storageCargoareaId: ids,
      }).then((res) => {
        if (res.data.status === "success") {
          this.goodsBoxNum = res.data.result;
          // this.formData1.storageCargoareaFrameNumber =  this.goodsBoxNum[0].frameNumber;
        } else {
          console.log(`${res.data.message}--获取货架`);
        }
      });
    },

    // 调用摄像头
    callCamera() {
      // H5调用电脑摄像头API
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((success) => {
          // 摄像头开启成功
          this.$refs["video"].srcObject = success;
          // 实时拍照效果
          this.$refs["video"].play();

          this.canimg = true;
          this.showCallCamera = true;
        })
        .catch((error) => {
          this.canimg = false;
          this.showCallCamera = false;
          console.error("摄像头开启失败，请检查摄像头是否可用！");
        });
    },

    // 不开本地摄像头,根据接口回调那图片信息的
    uploadImgs(imgBase64) {
      this.ImgSrc = imgBase64;
      // return
      let fileBase64 = imgBase64.replace("data:image/jpeg;base64,", "");

      //上传
      var fileName = new Date().getTime() + ".jpeg"; //随机文件名
      //base64转换为文件
      var imgfile = this.convertBase64UrlToImgFile(
        fileBase64,
        fileName,
        "image/jpeg"
      );
      this.uploadSectionFile(imgfile, fileName);
    },
    // 拍照
    photograph() {
      if (!this.canimg) {
        this.$message.error("摄像头不可用");
        this.showCallCamera = false;
        return false;
      }
      if (!this.showCallCamera) {
        // 摄像头已关闭
        return false;
      }
      let ctx = this.$refs["canvas"].getContext("2d");
      // 把当前视频帧内容渲染到canvas上
      ctx.drawImage(this.$refs["video"], 0, 0, 278, 208);
      // ctx.drawImage(this.$refs["video"], 0, 0, 640, 480);
      // 转base64格式、图片格式转换、图片质量压缩---支持两种格式image/jpeg+image/png
      let imgBase64 = this.$refs["canvas"].toDataURL("image/jpeg", 0.7);
      // console.log("imgBase64", imgBase64);
      this.ImgSrc = imgBase64;
      // return
      let fileBase64 = imgBase64.replace("data:image/jpeg;base64,", "");

      //上传
      var fileName = new Date().getTime() + ".jpeg"; //随机文件名
      //base64转换为文件
      var imgfile = this.convertBase64UrlToImgFile(
        fileBase64,
        fileName,
        "image/jpeg"
      );
      this.uploadSectionFile(imgfile, fileName);
      /**------------到这里为止，就拿到了base64位置的地址，后面是下载功能----------*/

      // 由字节转换为KB 判断大小
      // let str = imgBase64.replace("data:image/jpeg;base64,", "");
      // let strLength = str.length;
      // let fileLength = parseInt(strLength - (strLength / 8) * 2); // 图片尺寸  用于判断
      // let size = (fileLength / 1024).toFixed(2);
      // console.log(size); // 上传拍照信息  调用接口上传图片 .........
      // 保存到本地
      // let ADOM = document.createElement("a");
      // ADOM.href = this.ImgSrc;
      // ADOM.download = new Date().getTime() + ".jpeg";
      // ADOM.click();
    },
    // 关闭摄像头
    closeCamera() {
      if (!this.$refs["video"].srcObject) return;
      let stream = this.$refs["video"].srcObject;
      let tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.$refs["video"].srcObject = null;
      this.showCallCamera = false;
    },
    /**
     * 有些浏览器(如edge)不支持new File，所以为了兼容，base64要先转换成blob再设置type，name，lastModifiedDate
     * 属性间接转换成文件，而不推荐直接new File()的方式
     **/
    convertBase64UrlToImgFile(urlData, fileName, fileType) {
      var bytes = window.atob(urlData); //转换为byte
      //处理异常,将ascii码小于0的转换为大于0
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Int8Array(ab);
      var i;
      for (i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      //转换成文件，添加文件的type，name，lastModifiedDate属性
      var blob = new Blob([ab], { type: fileType });
      blob.lastModifiedDate = new Date();
      blob.name = fileName;
      return blob;
    },

    // 上传图片
    uploadSectionFile(file, fileName) {
      const form = new FormData(); // 根据后台需求数据格式
      form.append("file", file); // 文件对象
      form.append("fileName", fileName); // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      Api.uploadFile(form).then((res) => {
        if (res.data.status === "success") {
          // console.log('上传成功')
          let fileNameList = [];
          fileNameList.push(res.data.result.fileName);
          this.fileNameList = fileNameList;
          // this.$message.success(res.data.message || "图片上传成功");
          // this.fileList.push({
          //   name: res.data.result.fileName,
          //   uid: res.data.result.redirectUrl,
          //   url: res.data.result.url,
          // });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;

      let saoMiaoHeight = this.$refs.saoMiao.offsetHeight || "300"; // 扫描区
      let caoZuoHeight = this.$refs.caoZuo.offsetHeight || "500"; // 扫描区

      var otherHeight = saoMiaoHeight + caoZuoHeight + 30 + 40 + 60;
      return Math.floor(windonHeight - otherHeight) || "30vh"; //
    },
  },
};
</script>

<style scoped lang="scss">
$borderColor: #d7d7d7;
.mainBOx {
  font-size: 14px;
  // width: 100%;
  // height: calc(100vh - 90px);
  border: 1px solid $borderColor;
  // margin: 10px;
  display: flex;

  .main_left {
    width: 300px;
    border-right: 1px solid $borderColor;
    padding: 0;
    .tipsTitle {
      padding: 10px;
      background-color: #f2f2f2;
    }
    .margin10px {
      margin: 10px;
    }

    .left_bottom {
      padding: 10px;

      .left_item_param {
        margin-bottom: 20px;

        .imgs {
          border: 1px solid $borderColor;
          margin-bottom: 4px;
        }
      }
    }
  }
  .main_right {
    // flex: 1;
    width: calc(100% - 310px);

    .formClass {
      display: flex;
      flex-wrap: wrap;
      .queryItem1 {
        width: 300px;
        ::v-deep {
          .el-form-item {
            margin-bottom: 10px;
          }
          // .el-input--suffix .el-input__inner,
          // .el-input__inner,
          // .el-input.is-disabled .el-input__inner {
          //   padding-right: 15px;
          //   width: 150px;
          // }
        }
      }
    }

    .textareaitem {
      width: 600px;
    }

    .ordinarybut1 {
      margin: 0 100px 30px;
    }
    .right_center_box {
      border-bottom: 1px solid $borderColor;
    }
  }
  .main_item_top {
    min-height: 140px;
    border-bottom: 1px solid $borderColor;
    overflow: hidden;
  }

  .selectShebei {
    display: flex;
    font-size: 14px;
    align-items: center;
    border: 1px solid $borderColor;
    border-radius: 6px;
    margin: 10px;
    ::v-deep {
      .el-input__inner {
        border: none;
      }
    }

    .leftLabel {
      width: 80px;
      text-align: right;
      margin-right: 10px;
    }
    .rightInput {
      flex: 1;
    }
  }
  .tipstit {
    font-weight: bold;
    margin: 6px;
  }
  .right_tipstit {
    font-weight: bold;
    margin: 10px 20px;
  }
}
.canvas_s {
  display: none;
}

.imgsA {
  width: 280px;
  height: 280px;
}
</style>
